import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-unificator/services';
import { StaticContentService } from '../../core/services/static-content.service';
import { filter, tap } from 'rxjs/operators';

export const NATIVE_APP_COOKIE = 'isNativeApp';

@Injectable({
  providedIn: 'root'
})
export class AppInstallService {
  public notificationCookieName = '--notification-install-app';
  private _showInstallAppNotificationMessage: boolean;
  private _hideInstallAppByCMS: boolean;

  get showInstallAppNotificationMessage() {
    return this._showInstallAppNotificationMessage;
  }

  get isNativeApp(): boolean {
    return this._cookie.check(NATIVE_APP_COOKIE);
  }

  get hideInstallAppByCMS(): boolean {
    return this._hideInstallAppByCMS;
  }

  constructor(
    private _cookie: CookieService,
    private _static: StaticContentService,
  ) {
    this._resolveVariables();
  }

  private _resolveVariables() {
    this._showInstallAppNotificationMessage = !Boolean(this._cookie.get(this.notificationCookieName));
  }

  public closeNotificationBlock() {
    this._showInstallAppNotificationMessage = false;
    this._cookie.set(this.notificationCookieName, '1');
  }

  public checkIfShowInstallAppByCMS() {
    return this._static.item({slug: 'show-install-app'}).pipe(
      filter(list => !!list),
      tap((list) => this._hideInstallAppByCMS = !list.length)
    );
  }
}
